import { Component } from '@angular/core';
import { MatIconButton, MatButton, MatAnchor } from '@angular/material/button';
import { MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  // eslint-disable-next-line
  selector: 'modal-content',
  templateUrl: 'modal-content.component.html',
  styleUrls: ['modal-content.component.scss'],
  // eslint-disable-next-line
  host: { class: 'modal-flex-content' },
  imports: [
    MatDialogTitle,
    MatIconButton,
    MatDialogClose,
    MatIcon,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatAnchor,
    MatInputModule,
    MatFormFieldModule,
  ],
})
export class ModalContentComponent {}
