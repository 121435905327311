<h3>vertical cards</h3>
<div class="card-list">
  <mat-card>
    <div class="card-img-outer">
      <div class="card-img-inner">
        <img src="https://placehold.co/150x200/" alt="placeholder-image" />
      </div>
    </div>

    <mat-card-title><h3>vertical card - image cover</h3></mat-card-title>
    <mat-card-content>
      <p>Some test text</p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur.
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-icon-button><mat-icon>edit_outline</mat-icon></button>
      <button mat-icon-button><mat-icon>delete_outline</mat-icon></button>
    </mat-card-actions>
  </mat-card>

  <mat-card>
    <div class="card-img-outer">
      <div class="card-img-inner">
        <img src="https://placehold.co/150x200/" class="card-img-contain" alt="placeholder-image" />
      </div>
    </div>

    <mat-card-title><h3>vertical card - image contain</h3></mat-card-title>
    <mat-card-content>
      <p>Some test text</p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur.
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-icon-button><mat-icon>edit_outline</mat-icon></button>
      <button mat-icon-button><mat-icon>delete_outline</mat-icon></button>
    </mat-card-actions>
  </mat-card>

  <mat-card>
    <div class="card-img-outer">
      <div class="card-img-inner">
        <img src="https://placehold.co/250x200/" alt="placeholder-image" />
        <p class="advertising">advertising</p>
      </div>
    </div>

    <mat-card-title><h3>vertical card with advertising</h3></mat-card-title>
    <mat-card-content>
      <p>Some test text</p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur.
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button mat-icon-button><mat-icon>edit_outline</mat-icon></button>
      <button mat-icon-button><mat-icon>delete_outline</mat-icon></button>
    </mat-card-actions>
  </mat-card>

  <mat-card style="visibility: hidden"></mat-card>
</div>

<h3>portrait cards</h3>
<div class="card-list">
  <mat-card>
    <div class="portrait-card-content">
      <div class="portrait-img-outer">
        <div class="portrait-img-inner">
          <img src="https://placehold.co/200x150/" alt="placeholder-image" />
        </div>
      </div>

      <mat-card-content>
        <mat-card-title><h3>portrait card - image cover</h3></mat-card-title>
        <p>data type 1</p>
        <p>data type 2</p>
        <p>data type 3</p>
        <p>data type 4</p>
      </mat-card-content>
    </div>
  </mat-card>

  <mat-card>
    <div class="portrait-card-content">
      <div class="portrait-img-outer">
        <div class="portrait-img-inner">
          <img src="https://placehold.co/200x150/" class="card-img-contain" alt="placeholder-image" />
        </div>
      </div>

      <mat-card-content>
        <mat-card-title><h3>portrait card - image contain</h3></mat-card-title>
        <p>data type 1</p>
        <p>data type 2</p>
        <p>data type 3</p>
        <p>data type 4</p>
      </mat-card-content>
    </div>
  </mat-card>

  <mat-card>
    <div class="portrait-card-content">
      <div class="portrait-img-outer">
        <div class="portrait-img-inner">
          <img src="https://placehold.co/150x200/" alt="placeholder-image" />
        </div>
      </div>

      <mat-card-content>
        <mat-card-title><h3>portrait card with actions</h3></mat-card-title>
        <p>data type 1</p>
        <p>data type 2</p>
        <p>data type 3</p>
        <p>data type 4</p>

        <mat-card-actions>
          <button mat-icon-button><mat-icon>edit_outline</mat-icon></button>
          <button mat-icon-button><mat-icon>delete_outline</mat-icon></button>
        </mat-card-actions>
      </mat-card-content>
    </div>
  </mat-card>

  <mat-card style="visibility: hidden"></mat-card>
</div>

<h3>horizontal cards</h3>
<div class="card-list">
  <mat-card class="horizontal-card">
    <div class="horizontal-card-img-outer">
      <div class="horizontal-card-img-inner">
        <img class="horizontal-card-img" src="https://placehold.co/150x200/" alt="placeholder-image" />
      </div>
    </div>

    <mat-card-content>
      <mat-card-title><h3>horizontal card - image cover</h3></mat-card-title>
      <p>data type 1</p>
      <p>data type 2</p>
      <p>data type 3</p>
      <p>data type 4</p>
    </mat-card-content>
  </mat-card>

  <mat-card class="horizontal-card">
    <div class="horizontal-card-img-outer">
      <div class="horizontal-card-img-inner">
        <img class="horizontal-card-img card-img-contain" src="https://placehold.co/150x200/" alt="placeholder-image" />
      </div>
    </div>

    <mat-card-content>
      <mat-card-title><h3>horizontal card - image contain</h3></mat-card-title>
      <p>data type 1</p>
      <p>data type 2</p>
      <p>data type 3</p>
      <p>data type 4</p>
    </mat-card-content>
  </mat-card>

  <mat-card class="horizontal-card">
    <div class="horizontal-card-img-outer">
      <div class="horizontal-card-img-inner">
        <img class="horizontal-card-img" src="https://placehold.co/200x150/" alt="placeholder-image" />
      </div>
    </div>

    <mat-card-content>
      <mat-card-title><h3>horizontal card with actions</h3></mat-card-title>
      <p>data type 1</p>
      <p>data type 2</p>
      <p>data type 3</p>
      <p>data type 4</p>

      <mat-card-actions>
        <button mat-icon-button><mat-icon>edit_outline</mat-icon></button>
        <button mat-icon-button><mat-icon>delete_outline</mat-icon></button>
      </mat-card-actions>
    </mat-card-content>
  </mat-card>
</div>
