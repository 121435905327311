import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';

@Component({
  selector: 'app-input-clearable',
  templateUrl: './input-clearable.component.html',
  styleUrls: ['./input-clearable.component.scss'],
  imports: [
    MatFormField,
    MatLabel,
    MatInput,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    MatIconButton,
    MatSuffix,
    MatIcon,
  ],
})
export class InputClearableComponent {
  value = 'Clear me';
}
