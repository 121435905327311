import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MatTableDataSource,
  MatTable,
  MatColumnDef,
  MatHeaderCellDef,
  MatHeaderCell,
  MatCellDef,
  MatCell,
  MatFooterCellDef,
  MatFooterCell,
  MatHeaderRowDef,
  MatHeaderRow,
  MatRowDef,
  MatRow,
  MatFooterRowDef,
  MatFooterRow,
} from '@angular/material/table';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: string;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: '1.0079', symbol: 'H' },
  { position: 2, name: 'Helium', weight: '4.0026', symbol: 'He' },
  { position: 3, name: 'Lithium', weight: '6.941', symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: '9.0122', symbol: 'Be' },
  { position: 5, name: 'Boron', weight: '10.811', symbol: 'B' },
  { position: 6, name: 'Carbon', weight: '12.0107', symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: '14.0067', symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: '15.9994', symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: '18.9984', symbol: 'F' },
  { position: 10, name: 'Neon', weight: '20.1797435345452457673567346245624563', symbol: 'Ne' },
];

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  imports: [
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCellDef,
    MatCell,
    MatFooterCellDef,
    MatFooterCell,
    MatIconButton,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatFooterRowDef,
    MatFooterRow,
  ],
})
export class TableComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = ['name', 'position', 'symbol', 'weight', 'favourite'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
  }
}
